<span class="popup-link" (click)="openModal(modal)">{{ displayName }}</span>
<ng-template #modal let-modal>
  <div class="top">
    <span class="title">NURSING</span>
    <span class="position-btn material-icons" (click)="closeModal()"
      >close</span
    >
  </div>
  <div class="content-container modal-scrollable-content">
    <div
      *ngFor="let component of pageContent"
      [ngSwitch]="component.__component"
      class="components-container"
    >
      <div class="component-container">
        <app-paragraph
          *ngSwitchCase="componentTypes.Paragraph"
          [component]="component"
        ></app-paragraph>
        <app-image-gallery
          *ngSwitchCase="componentTypes.Gallery"
          [component]="component"
        ></app-image-gallery>
        <div *ngSwitchCase="componentTypes.Image" class="image-container">
          <ng-container *ngIf="component.img">
            <a *ngIf="component.link" href="{{ component.link }}">
              <img
                [imgUrlWithSize]="imageFormat"
                [imgUrl]="component.img.url"
                alt="Obrazek"
              />
            </a>
            <img
              *ngIf="!component.link"
              [imgUrlWithSize]="imageFormat"
              [imgUrl]="component.img.url"
              alt="Obrazek"
            />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
