<div *ngIf="!isMobile" class="desktop article-all">
  <a [routerLink]="['/artykul', article.slugId]">
    <img
      [imgUrlWithSize]="imageDesktopFormat"
      [imgUrl]="article.img.url"
      class="article-image"
      [ngClass]="imageSize"
      [alt]="article.title"
      loading="lazy"
    />
  </a>
  <div class="article-content">
    <div class="article-top-line">
      <div class="article-top-line-labels-container">
        <span
          *ngFor="let category of article.categories"
          class="article-top-line-label"
        >
          <a
            [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
            [ngStyle]="{ color: category.color }"
          >
            {{ category.name | uppercase }}
          </a>
        </span>
        <span class="article-top-line-label article-top-line-label-date">{{
          article.date | date: 'dd.MM.yyyy'
        }}</span>
      </div>

      <div class="article-top-line-controls-container">
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && !isFavorite(article.id)"
          (click)="addToFavorite(article.id)"
        >
          <span class="material-icons favorite-icon-add">favorite_border</span>
        </button>
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && isFavorite(article.id)"
          (click)="removeFromFavorite(article.id)"
        >
          <span class="material-icons favorite-icon-remove">favorite</span>
        </button>
      </div>
    </div>

    <a
      [routerLink]="['/artykul', article.slugId]"
      class="p-title"
      [innerHtml]="article.title"
    ></a>
    <a [routerLink]="['/artykul', article.slugId]" class="read-more-link">
      <p class="read-more-container">
        <span class="read-more">{{ readMore }}</span>
        <span class="arrow_right material-icons">
          trending_flat
        </span>
      </p>
    </a>
  </div>
</div>

<div *ngIf="isMobile" class="mobile article-all">
  <a [routerLink]="['/artykul', article.slugId]">
    <img
      [imgUrlWithSize]="imageMobileFormat"
      [imgUrl]="article.img.url"
      class="article-image"
      [ngClass]="imageSize"
      [alt]="article.title"
      loading="lazy"
    />
  </a>
  <div class="article-content">
    <div class="article-top-line">
      <div class="article-top-line-labels-container">
        <span
          *ngFor="let category of article.categories"
          class="article-top-line-label"
        >
          <a
            [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
            [ngStyle]="{ color: category.color }"
          >
            {{ category.name | uppercase }}
          </a>
        </span>
      </div>
      <div class="article-top-line-controls-container">
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && !isFavorite(article.id)"
          (click)="addToFavorite(article.id)"
        >
          <span class="material-icons favorite-icon-add">favorite_border</span>
        </button>
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && isFavorite(article.id)"
          (click)="removeFromFavorite(article.id)"
        >
          <span class="material-icons favorite-icon-remove">favorite</span>
        </button>
      </div>
    </div>
    <a [routerLink]="['/artykul', article.slugId]">
      <span class="p-title" [innerHtml]="article.title"></span>
    </a>
    <p class="date-container">
      <span class="p-date">{{ article.date | date: 'dd.MM.yyyy' }}</span>
    </p>
    <p class="read-more-container">
      <a [routerLink]="['/artykul', article.slugId]"
        >{{ readMore }}
        <span class="arrow_right material-icons">
          trending_flat
        </span>
      </a>
    </p>
  </div>
</div>
<hr />
