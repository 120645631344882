<ng-container
  *ngFor="let component of article?.structure"
  [ngSwitch]="component.__component"
>
  <app-ad-video
    *ngSwitchCase="componentTypes.AdVideo"
    [component]="component"
  ></app-ad-video>
</ng-container>
<div
  *ngFor="let component of article?.structure"
  [ngSwitch]="component.__component"
  class="component-container"
>
  <app-image-gallery
    *ngSwitchCase="componentTypes.Gallery"
    [component]="component"
  ></app-image-gallery>
  <app-paragraph
    *ngSwitchCase="componentTypes.Paragraph"
    [component]="component"
  ></app-paragraph>
  <app-article-attachment-list
    *ngSwitchCase="componentTypes.AttachmentList"
    [attachments]="component"
  >
  </app-article-attachment-list>
  <app-contextual-ad
    *ngSwitchCase="componentTypes.ContextualAd"
    [component]="component"
  ></app-contextual-ad>
  <app-closed-marker
    *ngSwitchCase="componentTypes.ClosedMarker"
    [article]="article"
  ></app-closed-marker>
  <div *ngSwitchCase="componentTypes.Image" class="image-container">
    <ng-container *ngIf="component.img">
      <a *ngIf="component.link" href="{{ component.link }}">
        <img
          [imgUrlWithSize]="imageFormat"
          [imgUrl]="component.img.url"
          [alt]="article?.title"
        />
      </a>
      <img
        *ngIf="!component.link"
        [imgUrlWithSize]="imageFormat"
        [imgUrl]="component.img.url"
        [alt]="article?.title"
      />
    </ng-container>
  </div>
  <app-ad
    *ngSwitchCase="componentTypes.Ad"
    [component]="component"
    [categories]="article?.categories"
  ></app-ad>
  <app-custom-html
    *ngSwitchCase="componentTypes.CustomHtml"
    [component]="component"
  ></app-custom-html>
</div>
